/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ExclamationCircleOutlined, FilterOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import { readFile, utils } from 'xlsx';
import { Collapse, Table, Switch, Button, Checkbox, Select, Input, Tooltip, Modal } from 'antd';
import {
  DownloadOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  SearchOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
// import SuperAdminFields from './superAdminFields';
import { useHistory } from 'react-router';
import AdminAPIs from '../../../../api/admin';
import { set } from 'lodash-es';
import _ from 'lodash';
import ExportReactCSV from '../../../../helpers/export';
import { lastDayOfMonth } from 'date-fns';
import { id } from 'date-fns/locale';
import BusinessUserFields from './businessUserFields';
import { handleSuccessMessages ,handleErrorMessages} from '../../../../helpers/flashMessages';
import { CSVLink } from 'react-csv';
import UserAPIs from '../../../../api/user';
import Swal from 'sweetalert2';

const BusinessUser = () => {
  // const { sidebarContent, setRenderContent } = useContext(RenderContentContext);
  const history = useHistory();
  const [showMerge, setShowMerge] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [businessUsers, setBusinessUser] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedMergedRows, setSelectedMergedRows] = useState([]);
  const [selectedMergedUserNames, setSelectedMergedUserNames] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [collapse, setCollapse] = useState([]);
  const alert = useAlert();
  const [table, setTable] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [garages, setGarages] = useState([]);
  const [garage, setGarage] = useState('');
  const { Search } = Input;
  const { Panel } = Collapse;
  const { Option, OptGroup } = Select;
     //new changes
     const [selectedPrimary, setSelectedPrimary] = useState(null);
     const [selectedPrimaryUserName, setSelectedPrimaryUserName] = useState(null);
     const [selectedNonPrimaryUserName, setSelectedNonPrimaryUserName] = useState(null);
   
   const onSelectMergeRow = (e, user) => {
    console.log(e,user,"selectedMergedUserNames");
     if (e.target.checked) {
       if (selectedMergedRows.length < 2) {
         setSelectedMergedRows([...selectedMergedRows, user._id]);
         setSelectedMergedUserNames([...selectedMergedUserNames, user.firstName]);
         //setSelectedEmails([...selectedEmails, user.email]);
       }
     } else {
       const selectedIds = selectedMergedRows.filter(n => n !== user._id);
       const selectedNames = selectedMergedUserNames.filter(n => n !== user.firstName);
       setSelectedMergedRows(selectedIds);
       setSelectedMergedUserNames(selectedNames);
   
       // If the deselected user is the primary, reset the primary selection
       if (selectedPrimary === user._id) {
         setSelectedPrimary(null);
         setSelectedPrimaryUserName(null);
         setSelectedNonPrimaryUserName(null);
       }
     }
   };
   
   const onSelectPrimary = (e, user) => {
     console.log(selectedMergedUserNames,"selectedMergedUserNames");
     if (e.target.checked) {
       setSelectedPrimary(user._id);
       setSelectedPrimaryUserName(user.firstName);
       setSelectedNonPrimaryUserName(
         selectedMergedUserNames.filter(name => name !== user.firstName).join(" ")
       );
     } else {
       setSelectedPrimary(null);
       setSelectedPrimaryUserName(null);
       setSelectedNonPrimaryUserName(null);
     }
   };
     //new changes
  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: '#F0734F',
      }}
    />
  );
  const [dataState, setDataState] = useState([]);
  const handleFile = async e => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = readFile(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsondata = utils.sheet_to_json(worksheet);
    setDataState(
      jsondata.map((e, i) => ({
        role: e.role,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        mobile: e.mobile,
      }))
    );
    setTable(
      jsondata.map((e, i) => ({
        role: e.role,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        mobile: e.mobile,
        createdAt: e.createdAt,
      }))
    );
  };
  const [options, setOptions] = useState({
    search: '',
    status: '',
    role: 'business_user',
    garage: '605b5b226ec4715ce5e0e00d',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
    start: '',
    end: '',
  });
  const { confirm } = Modal;
  const columns = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(businessUsers.map(x => x.userId)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  businessUsers.map(x => x.userId),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: 'userId',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: userId=> <Checkbox onChange={e => onSelectRow(e, userId)} checked={selectedRows.includes(userId)} />,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      // sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      // defaultSortOrder: 'descend',
      // sortDirections: ['ascend'],
      render: createdAt => (createdAt ? moment(createdAt).format('MMMM Do YYYY') : 'NA'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: status => `${status ? 'Active' : 'Inactive'}`,
    },
    {
      title: 'Actions',
      dataIndex: 'userId',
      // eslint-disable-next-line react/display-name
      render: userId => (
        <>
          <Link to={`/admin/business-users/edit/${userId}`} onClick={e => editOnClick(e, userId)}>
            <Tooltip placement="top" title="edit">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, userId)} />
          </Tooltip>
        </>
      ),
    },
  ];
  
  const mergeTableColumns = [
    {
      title: '',
      dataIndex: 'user',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: user => (
        <Checkbox
           onChange={e => onSelectMergeRow(e, user)}
           checked={selectedMergedRows.includes(user._id)}
           disabled={!selectedMergedRows.includes(user._id) && selectedMergedRows.length >= 2} // Disable when two users are already selected
         />
       ),
    },
    {
      title: 'Is Primary',
      dataIndex: 'user',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: user => (
         <Checkbox
         onChange={e => onSelectPrimary(e, user)}
         checked={selectedPrimary === user._id}
         disabled={!selectedMergedRows.includes(user._id)} // Disable when two users are already selected
        />
      ),
      
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      // sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      // defaultSortOrder: 'descend',
      // sortDirections: ['ascend'],
      render: createdAt => (createdAt ? moment(createdAt).format('MMMM Do YYYY') : 'NA'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: status => `${status ? 'Active' : 'Inactive'}`,
    },
    {
      title: 'Actions',
      dataIndex: 'userId',
      // eslint-disable-next-line react/display-name
      render: userId => (
        <>
          <Link to={`/admin/business-users/edit/${userId}`} onClick={e => editOnClick(e, userId)}>
            <Tooltip placement="top" title="edit">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, userId)} />
          </Tooltip>
        </>
      ),
    },
  ];
  const handleMerge =  async (selectedRows, primaryUserId) => {
    if (selectedRows.length !== 2 || !primaryUserId) {
       console.error("Invalid state: Ensure two rows are selected and a primary user is chosen.");
       return;
     }
   
     const [firstUserId, secondUserId] = selectedRows; // Destructure the two selected user IDs
   
     
     const body = {
       users: [firstUserId, secondUserId], // The two selected user IDs
       primaryUser: primaryUserId,        // The selected primary user ID
     };
     setIsLoading(true);
     try {
       const response = await UserAPIs.mergeCustomers(body); // Call the mergeCustomers function
       console.log(response.data.message);
       response.data.message ='Business User Merged Successfully';
       handleSuccessMessages(response, alert);
       
       setSelectedMergedRows([]);
       setSelectedMergedUserNames([]);
       setSelectedPrimary(null);
       setSelectedPrimaryUserName(null);
       setSelectedNonPrimaryUserName(null);
       getBusinessUserList(options);
       
     } catch (error) {
       console.error("Error merging customers:", error);
       const message = handleErrorMessages(error, alert);
       setError(message)
 
     } finally {
       setIsLoading(false);
     }
   };

  const onSwitchChange = async (checked, status, id) => {
    try {
      const data = { status: checked };
      const response = await AdminAPIs.updateAdmin(id, data);
      getBusinessUserList(options);
    } catch (error) {
      console.log(error);
    }
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const datePicker = () => {
  const onChangedate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setOptions({
      ...options,
      start: moment(start).format('YYYY-MM-DD'),
      // end: moment(end).add(1, 'days').format('YYYY-MM-DD'),
      end: moment(end).format('YYYY-MM-DD'),
    });
  };
  // };

  const showDeleteConfirm = (e, _id) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        const response = AdminAPIs.deleteUser(_id);
        handleSuccessMessages({ customMessage: `Business User deleted successfully.` }, alert);
        setTimeout(()=>{
          getBusinessUserList(options);
        },1000)
        
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };

  const showDelete = (e, selectedRows) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
      
        const response = AdminAPIs.deleteMultipleUsers([selectedRows]);
        handleSuccessMessages({ customMessage: `Business User deleted successfully.` }, alert);
        setTimeout(()=>{
          getBusinessUserList(options);
        },1000)
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };

  const collapseShowHideFunc = key => {
    setCollapse(key);
  };
  const handleFilterClick = () => {
    setCollapse([]);
    setOptions({ ...options, garage: garages });
    getBusinessUserList(options);
    // getAllBusinessUserList(options);
    // getUserList({ ...options, pagination: { ...options.pagination, current: 1 } });
  };
  const handleClearAllClick = () => {
    setCollapse([]);
    const updatedOptions = {
      ...options,
      status: '',
      role: '',
      pagination: { ...options.pagination, current: 1 },
      start: '',
      end: '',
    };
    setStartDate(null);
    setEndDate(null);
    setOptions(updatedOptions);
    getBusinessUserList(updatedOptions);
    getAllBusinessUserList(updatedOptions);
  };
  const editOnClick = (e, user) => {};
  function handleChange(value) {}

  useEffect(() => {
    getBusinessUserList(options);
    // getAllUserList(options);
  }, []);
  const getBusinessUserList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getBusinessUsers(options);
      const { customers } = response.data.customers;
      setBusinessUser(customers.docs);
      setStats(customers.stats)
      setOptions({
        ...options,
        pagination: {
          current: customers.page,
          pageSize: customers.limit,
          total: customers.totalDocs,
          showSizeChanger: true,
        },
      });
    } catch (e) {
      console.error('e');
      setLoading(false);
    }
    setLoading(false);
  };

  const getAllBusinessUserList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getBusinessUsers(options);
      let finaldata = response.data.users 
      finaldata.map((data)=>{
      ['_id','password','profilePic','customerId','isDeleted','__v','customers'].map(item=>delete data[item])
      data.createdAt=moment(data.createdAt).format('DD-MM-yyyy')
     })
      setExportData(finaldata);
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  };
  const getAllUserList = async options => {
    try {
      setLoading(true);
      const response = await AdminAPIs.getAllUsers(options);
     let finaldata = response.data.users || [] 
      finaldata.map((data)=>{
      ['_id','password','profilePic','customerId','isDeleted','__v','customers'].map(item=>delete data[item])
      data.createdAt=moment(data.createdAt).format('DD-MM-yyyy')
     })
      setExportData(finaldata);
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  };
  const handleExport = async options => {
    try {
      setLoading(true);
      options.role='business_user'
     // options.status='true'
      const response = await AdminAPIs.getAllUsers(options);
      if(response.data.users.length >0)
       {
        let finaldata = response.data.users || [];
        let updateData = [];
        finaldata.forEach((el) => {
          const { user,province,postalCode,city,street} = el;
          updateData.push({
           FistName: user.firstName,
           LastName: user.lastName,
           Address: street,
           CityProvince: `${city} ${province ? `/${province}` :''}`,
           PostalCode: postalCode,
         });
        });
   
        setExportData(updateData);
      document.getElementById('businessUser').click();
       }
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  };
  const handleExportAll = async options => {
    try {
      setLoading(true);
      // debugger // eslint-disable-line no-debugger
      options.role='business_user';
      options.status='';
      const response = await AdminAPIs.getAllUsers(options);
      if(response.data.users.length >0)
      {
       let finaldata = response.data.users || [];
       let updateData = [];

       finaldata.forEach((el) => {
        const { user,province,postalCode,city,street} = el;
        updateData.push({
         FistName: user.firstName,
         LastName: user.lastName,
         Address: street,
         CityProvince: `${city} ${province ? `/${province}` :''}`,
         PostalCode: postalCode,
       });
      });
       setExportData(updateData);
       document.getElementById('businessUserAll').click();

      }
    } catch (e) {
      setLoading(false);
      console.error('e');
    }
    setLoading(false);
  };

  const getAllCustomerListWithSalesData = async (options) => {
    try {
      setLoading(true);
      options.role = 'business_user'; 
      //options.status = 'true';   
      options.sortOrder = '';
      const response = await AdminAPIs.getAllCustomerExportWithSalesData(options);
      if (response.data.users.length > 0) {
        let finaldata = response.data.users || [];
        let updateData = [];
        finaldata.forEach((user) => {
          const { firstName, lastName, postalCode, city, email, salesData } = user;
          const { breakdown, lifetimeDiscount, lifetimeAmount, lifetimeOilChange } = salesData;
          updateData.push({
            "First Name": firstName,
            "Last Name": lastName,
            "Postal Code": postalCode,
            "City": city,
            // "Email": email,
            "Total Amount": `$${lifetimeAmount}`,
            // "Total Discount": `$${lifetimeDiscount}`,
            // "Total Service Discount": lifetimeOilChange,
          });
        
          const rows = breakdown.map(row => {
            const { year, discount, discountLevel, oilChangeDiscount, total } = row
            updateData.push({
              // "Discount": discount,
              // "Service Discount": oilChangeDiscount,
              "Year": year,
              "Discount Level": discountLevel,
              "Total": `$${total}`,
            });
          })
        })
        setExportData(updateData);
        document.getElementById('csvLinkSalesData').click();
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getGarages();
        const { garages } = response.data;
        setGarages(garages);
        if (garages.length) {
          setGarage(garages[0]);
          setOptions({ ...options, garage: garages[0]._id });
          getBusinessUserList({ ...options, garage: garages[0]._id });
        }
      } catch (e) {
        console.log(e);
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);

  function onChange(pagination, filters, sorter, extra) {
    // const {current, pageSize} = pagination;
    // const updatedOptions = { ...options, current, pageSize };
    const updatedOptions = { ...options, pagination };
    setOptions(updatedOptions);
    getBusinessUserList({
      ...updatedOptions,
      sortFilters: {
        sortField: sorter?.columnKey,
        sortOrder: sorter?.order,
      },
    });
  }

  const onSearch = value => console.log(value);

  const onSelectAll = e => {
    if (e.target.checked) {
      const test = [...new Set([...selectedRows, businessUsers.map(n => n.userId)].flat())];
      setSelectedRows(test);
    } else {
      const selectedIds = selectedRows.filter(n => !businessUsers.map(x => x.userId).includes(n));
      setSelectedRows(selectedIds);
    }
  };
  const onSelectRow = async (e, _id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, _id]);
    } else {
      const selectedIds = selectedRows.filter(n => n !== _id);
      setSelectedRows(selectedIds);
    }
  };

  const onSelect = e => {
    const newOptions = {
      search: options.search,
      status: options.status,
      garage: e,
      pagination: options.pagination,
      sortFilters: options.sortFilters,
      start: options.start,
      end: options.end,
    };
    setOptions({ ...options, garage: e });
    getBusinessUserList(newOptions);
  };

  const filterSortedTable = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const query = e.target.value;
    const updatedOptions = {
      ...options,
      search: query.trim(),
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    setTypingTimeout(() =>
      setTimeout(() => {
        getBusinessUserList(updatedOptions);
      }, 1000)
    );
  };
  return (
    <>
      {/* <Helmet>
        <script
          src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js"
          type="text/javascript"
        />
        <script src="http://localhost:3000/js/init-alpine.js" type="text/javascript" />
      </Helmet> */}
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">Business Users</h2>
          <div className="right--btn">
            <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
              <Link to="/admin/add-businessUser">
                <FiPlus className="inline-block align-middle mr-2 text-base" />
                <p className="inline-block align-middle">Add Business User</p>
              </Link>
            </button>
          </div>
        </div>
        <div className="d-flex filter--wrap my-5">
          <div className="d-flex mb-0 mb-sm-0 gap-2 flex-wrap sm:flex-nowrap">
            <div className="del--icon" style={selectedRows.length === 0 ? { cursor: 'default' } : {}}>
              <DeleteOutlined
                onClick={e => (selectedRows.length === 0 ? {} : showDelete(e, selectedRows))}
                style={selectedRows.length === 0 ? { cursor: 'default' } : {}}
              />
            </div>
            <Select
              style={{ width: 200 }}
              showSearch
              placeholder="select garage"
              optionFilterProp="children"
              onSelect={e => {
                onSelect(e);
              }}
              onChange={e => {
                setGarage(garages.find(selectedGarage => selectedGarage._id === e));
                setOptions({ ...options, garage: e });
                setSelectedMergedRows([]);
                setSelectedMergedUserNames([]);
                setSelectedPrimary(null);
                setSelectedPrimaryUserName(null);
                setSelectedNonPrimaryUserName(null);
              }}
              onSearch={onSearch}
              value={garage ? garage._id : 'Test'}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {garages.map((garage, i) => (
                <option key={i.toString()} value={garage._id}>
                  {_.startCase(garage.name)}
                </option>
              ))}
            </Select>
            <div className="filter_content w-100 d-flex">
              <Collapse activeKey={collapse} onChange={collapseShowHideFunc}>
                <Panel
                  showArrow={false}
                  header={
                    <React.Fragment>
                      <FilterOutlined className="mr-2" style={{ marginBottom: '1px' }} />
                      <span className="anticon anticon-filter">Filters</span>
                    </React.Fragment>
                  }
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-12 filter_header d-flex flex-wrap position-relative">
                        <h4 className="title">Filter By</h4>
                        <button className="clearfilter_btn btn-default" onClick={handleClearAllClick}>
                          Clear all
                        </button>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Status</label>
                        <Select
                          className="text-1 font-normal rounded-r-none active-inactive-filter"
                          name="status"
                          defaultValue="All"
                          value={options.status}
                          onChange={e => setOptions({ ...options, status: e })}
                        >
                          <Option value="">All</Option>
                          <Option value={true}>Active</Option>
                          <Option value={false}>Inactive</Option>
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 fltr_cell">
                        <label className="label">Date</label>
                        <label>
                          <DatePicker
                            className="date-picker"
                            selected={startDate}
                            onChange={onChangedate}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            withPortal
                            placeholderText="MM/DD/YYYY"
                            // inline
                            isClearable={false}
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell d-flex justify-content-end">
                        <label className="label"></label>
                        <button
                          type="button"
                          className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm"
                          onClick={handleFilterClick}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>

          {/* <div className="d-flex">
            <label className="custom-field mr-2">
              <input type="file" name="file" onChange={e => handleFile(e)} />
              <span className="placeholder ant-btn ant-btn-primary">
                <DownloadOutlined /> Import
              </span>
            </label>
            <ExportReactCSV
              csvData={exportData}
              fileName={'Users.csv'}
              handleSubmit={() => getAllBusinessUserList(options)}
            />
            <Search
              className="search--wrap mt-3 mt-sm-0"
              style={{ width: '270px' }}
              placeholder="input search text"
              enterButton="Search"
              size="large"
              addonBefore={suffix}
              onChange={e => filterSortedTable(e)}
              // onSearch={onSearch}
            />
          </div> */}
          <div className="d-flex flex-wrap gap-2">
          {showMerge ? (
                  <>
          
              <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary"   
                      onClick={() => {
                         setSelectedMergedRows([]);
                         setSelectedMergedUserNames([]);
                         setShowMerge(false);
                         setSelectedPrimary(null);
                         setSelectedPrimaryUserName(null);
                }}>Merge Business Users
              </Button>
              <label className="custom-field ">
                <input type="file" name="file" onChange={e => handleFile(e)} />
                <span className="placeholder ant-btn ant-btn-primary">
                  <DownloadOutlined /> Import
                </span>
              </label>
              <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary" icon={<UploadOutlined className='mr-1' />}  onClick={() => handleExport(options)}>
                Export 
              </Button>
              <CSVLink
                id="businessUser"
                data={exportData}
                filename={'Business.csv'}
                style={{ display: 'none' }}
              >
                Export 
              </CSVLink>
              <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary" icon={<UploadOutlined className='mr-1' />}  onClick={() => handleExportAll(options)}>
                Export All
              </Button>
              <CSVLink
                id="businessUserAll"
                data={exportData}
                filename={'Business.csv'}
                style={{ display: 'none' }}
              >
                Export 
              </CSVLink>
              <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary" icon={<UploadOutlined className='mr-1' />} onClick={() => getAllCustomerListWithSalesData(options)}>
                Export With Sales Data
              </Button>
              <CSVLink
                id="csvLinkSalesData"
                data={exportData}
                filename={'business-user-data.csv'}
                style={{ textDecoration: 'none', allColumns: true, align: 'left' }}
              >
              </CSVLink>
                </>
            ) : (
              <Button className="mr-2 placeholder ant-btn ant-btn-primary" type="primary"   
                  onClick={() => {
                    setSelectedMergedRows([]);
                    setSelectedMergedUserNames([]);
                    setShowMerge(true);
                    setSelectedPrimary(null);
                    setSelectedPrimaryUserName(null);
              
               }}>All Business Users
              </Button>
            )}
            <Search
              className="input-search-box search--wrap mt-3 mt-sm-0"
              placeholder="input search text"
              // enterButton="Search"
              size="large"
              onChange={e => filterSortedTable(e)}
              // addonBefore={suffix}
              onSearch={onSearch}
            />
          </div>
        </div>
      </div>
      {showMerge ? (
        <div className="d-flex align-items-center px-3 pb-3">
          <p className="mr-3 font-weight-bold">Total Business Users: <span className="text-primary">{stats.totalCustomers}</span></p>
          <p className="mr-3 font-weight-bold">Active Business Users: <span style={{ color: '#008000' }}>{stats.totalActive}</span></p>
          <p className="font-weight-bold">Inactive Business Users: <span className="text-danger">{stats.totalInactive}</span></p>
        </div>
      ) : (
        <div style={{ marginBottom: 16 }} className="d-flex align-items-center px-3 pb-3">
          <Button
          //  className="bg-primary hover:bg-opacity-80 py-1 px-4 text-white rounded-sm"
            className="mr-2 placeholder ant-btn ant-btn-primary"
            onClick={() => {
            const message = `You are about to merge <b>${selectedNonPrimaryUserName}</b> in to <b>${selectedPrimaryUserName}</b>. Merging data includes a complete transfer of Repair orders, customer, and vehicles history.<br><br><strong>Warning:</strong> This process is permanent.<br><br>Would you like to proceed?`;
              Swal.fire({
                title: 'Are you sure?',
                html: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, merge it!',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  // Proceed with the merge if the user confirms
                  handleMerge(selectedMergedRows, selectedPrimary);
                } else {
                  // Optionally handle cancel action
                  console.log('Merge cancelled');
                }
              });
            }}
            
            
            disabled={isLoading || !(selectedMergedRows.length === 2 && selectedPrimary)}
            >
            Merge
          </Button>
          <span style={{ marginLeft: 8 }}>
            {selectedMergedRows.length > 0 ? `Selected ${selectedMergedRows.length} items` : ''}
          </span>
        </div>
      )}
      <main className="overflow-y-auto main--table">
      {showMerge ? (
          
          <div id="all_customers">
            <div className="grid mx-auto">
              <div className="w-full mb-8 overflow-hidden shadow-xs">
                <div className="w-full overflow-x-auto">
                  <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={dataState.length > 0 ? table : businessUsers}
                    // dataSource={table}
                    onChange={onChange}
                    pagination={options.pagination.total > 10 ? options.pagination : false}
                    sortDirections={['ascend', 'descend']}
                    loading={loading}
                    scroll={{ scrollToFirstRowOnChange: true }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id="merge_customers">
            <div className="grid mx-auto">
              <div className="w-full mb-8 overflow-hidden shadow-xs">
                <div className="w-full overflow-x-auto">
                  <Table
                    columns={mergeTableColumns}
                    dataSource={dataState.length > 0 ? table : businessUsers}
                    sortDirections={['ascend', 'descend']}
                    onChange={onChange}
                    pagination={options.pagination.total > 10 ? options.pagination : false}
                    loading={loading}
                    scroll={{ scrollToFirstRowOnChange: true }}
                  />
                </div>
              </div>
            </div>

          </div>
        )}
      </main>
    </>
  );
};
export default BusinessUser;
