/* eslint-disable prettier/prettier */
import HttpService from '../services/http';

const getAllCustomers = filters => {
  const {
    pagination: { current, pageSize },
    search,
    status,
    garage,
    sortFilters: { sortField, sortOrder },
  } = filters;
  let queryString = `q=${search}&garage=${garage}&status=${status}&limit=${pageSize}&page=${current}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}`;
  const http = new HttpService(`/admin/customers?${queryString}`);
  return http.get();
};

const getAdmin = id => {
  const http = new HttpService(`/admin/super-admin/${id}`);
  return http.get();
};

const deleteUser = id => {
  const http = new HttpService(`/admin/delete/${id}`);
  return http.put();
};

const deleteMultipleUsers = ([id]) => {
  const http = new HttpService(`/admin/delete/users/${[id]}`);
  return http.put();
};

const deleteVehicle = id => {
  const http = new HttpService(`/vehicle/${id}`);
  return http.delete();
};

const deleteVehicles = ([id]) => {
  const http = new HttpService(`/vehicle/delete/vehicles/${[id]}`);
  return http.delete();
};

const getAllCustomerExport = filters => {
  const {
    search,
    sortFilters: { sortField, sortOrder },
    garage,
    start,
    end,
  } = filters;
  let queryString = `garage=${garage}&q=${search}&sortField=${sortField || 'createdAt'}&sortOrder=${
    sortOrder || 'descend'
  }&start=${start}&end=${end}`;
  const http = new HttpService(`/admin/export-customers?${queryString}`);
  return http.get();
};

const getAllCustomerExportWithSalesData = filters => {
  const {
    role,
    status,
    search,
    sortFilters: { sortField, sortOrder },
    garage,
    start,
    end,
  } = filters;
  let queryString = `garage=${garage}&q=${search}&role=${role}&status=${status}&sortField=${sortField || ''}&sortOrder=${
    sortOrder || ''
  }&start=${start}&end=${end}`;
  const http = new HttpService(`/admin/export-customers-with-sales-data?${queryString}`);
  return http.get();
};

const getAllAdmin = filters => {
  const {
    pagination: { current, pageSize },
    search,
    sortFilters: { sortField, sortOrder },
    start,
    end,
    status,
  } = filters;
  let queryString = `status=${status}&q=${search}&limit=${pageSize || 10}&page=${current || 1}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&start=${start}&end=${end}`;
  const http = new HttpService(`/admin/super-admin/all?${queryString}`);
  return http.get();
};
const getAllAdminExport = filters => {
  const {
    pagination: { current, pageSize },
    search,
    sortFilters: { sortField, sortOrder },
    start,
    end,
    status,
  } = filters;
  let queryString = `status=${status}&q=${search}&limit=${pageSize}&page=${current}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&start=${start}&end=${end}`;
  const http = new HttpService(`/admin/export-admins?${queryString}`);
  return http.get();
};

const getUser = id => {
  const http = new HttpService(`/admin/user/edit/${id}`);
  return http.get();
};

const getUsers = filters => {
  const {
    pagination: { current, pageSize },
    search,
    role,
    status,
    sortFilters: { sortField, sortOrder },
    start,
    end,
  } = filters;
  let queryString = `status=${status}&q=${search}&limit=${pageSize || 10}&page=${current || 1}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&role=${role}&start=${start}&end=${end}`;
  const http = new HttpService(`/admin/users/all?${queryString}`);
  return http.get();
};

const getAllUsers = filters => {
  const {
    search,
    role,
    status,
    sortFilters: { sortField, sortOrder },
    start,
    end,
    garage
  } = filters;
  let queryString = `status=${status}&q=${search}&sortField=${sortField || ''}&sortOrder=${
    sortOrder || ''
  }&role=${role}&start=${start}&end=${end}${garage ? `&garage=${garage}`:'&garage'}`;
  const http = new HttpService(`/admin/export-users?${queryString}`);
  return http.get();
};

const addUsers = body => {
  const http = new HttpService(`/admin/user/add`);
  return http.post(body);
};

const updateUser = (id, body) => {
  const http = new HttpService(`/admin/user/update/${id}`);
  return http.put(body);
};

const updateAdmin = (id, body) => {
  const http = new HttpService(`/admin/super-admin/update/${id}`);
  return http.put(body);
};

const getGarages = () => {
  // let idd = `garage_id=${id}`;
  const http = new HttpService(`/garage/`);
  return http.get();
};

const getGarageOwners = filters => {
  const {
    pagination: { current, pageSize },
    status,
    search,
    sortFilters: { sortField, sortOrder },
    start,
    end,
  } = filters;
  let queryString = `keyword=${search}&status=${status}&limit=${pageSize || 10}&page=${current || 1}&sort_field=${
    sortField || ''
  }&sort_order=${sortOrder || ''}&start=${start}&end=${end}`;
  const http = new HttpService(`/admin/owner/all?${queryString}`);
  return http.get();
};

const getOwner = id => {
  const http = new HttpService(`/admin/owner/${id}`);
  return http.get();
};

const updateGarageName = (id, body) => {
  const http = new HttpService(`/admin/owner/update/${id}`);
  return http.put(body);
};

const updateDashboardGarageName = (garageId, newGarageName) => {
  const http = new HttpService(`/admin/garage/update`);
  return http.put({ garageId, newGarageName });
};

const updateGarageLabourRate = (body) => {
  const http = new HttpService(`/admin/labourRate/update`);
  return http.put(body);
};

const addAdmin = body => {
  const http = new HttpService(`/admin/super-admin/add`);
  return http.post(body);
};

const addOwner = (garageId, body) => {
  const http = new HttpService(`/admin/add/owner`);
  return http.post(garageId, body);
};

const getAllCustomer = filters => {
  const {
    pagination: { current, pageSize },
    search,
    status,
    garage,
    sortFilters: { sortField, sortOrder },
    start,
    end,
  } = filters;
  let queryString = `garage=${garage}&q=${search}&status=${status}&limit=${pageSize}&page=${current}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&start=${start}&end=${end}`;
  const http = new HttpService(`/admin/customer/all?${queryString}`);
  return http.get();
};

// const getAllVehicles = filters => {
//   const {
//     pagination: { current, pageSize },
//     status,
//     search,
//     customers,
//     start,
//     end,
//     sortFilters: { sortField, sortOrder },
//   } = filters;
//   let queryString = `customer=${customers || ''}&q=${search}&limit=${pageSize}&page=${current}&sortField=${
//     sortField || ''
//   }&sortOrder=${sortOrder || ''}&status=${status}&start=${start}&end=${end}`;
//   const http = new HttpService(`/vehicle?${queryString}`);
//   return http.get();
// };

const getAllVehiclesPerCustomer = filters => {
  const {
    pagination: { current, pageSize },
    search,
    customers,
    start,
    end,
    sortFilters: { sortField, sortOrder },
  } = filters;
  let queryString = `customer=${customers}&q=${search}&limit=${pageSize}&page=${current}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&start=${start}&end=${end}`;
  const http = new HttpService(`/vehicle/${customers}?${queryString}`);
  return http.get();
};

const addCustomer = body => {
  const http = new HttpService(`/admin/customer/add`);
  return http.post(body);
};

const updateCustomer = (id, body) => {
  const http = new HttpService(`/admin/customer/update/${id}`);
  return http.put(body);
};

const getCustomer = id => {
  const http = new HttpService(`/admin/customer/${id}`);
  return http.get();
};
const addVehicle = (body, id) => {
  const http = new HttpService(`/vehicle/${id}`);
  return http.post(body);
};
const getAllUsersName = () => {
  const http = new HttpService(`/admin/auto-suggestion`);
  return http.get();
};
const getCustomersName = () => {
  const http = new HttpService(`/admin/auto-suggestion/customers`);
  return http.get();
};
const getBusinessUsers = filters => {
  const {
    pagination: { current, pageSize },
    search,
    status,
    garage,
    sortFilters: { sortField, sortOrder },
    start,
    end,
  } = filters;
  let queryString = `garage=${garage}&status=${status}&q=${search}&limit=${pageSize || 10}&page=${current || 1}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&start=${start}&end=${end}`;
  const http = new HttpService(`/admin/business-user/all?${queryString}`);
  return http.get();
};
const addBusinessUsers = body => {
  const http = new HttpService(`/admin/customer/add`);
  return http.post(body);
};
const getUsersCount = () => {
  const http = new HttpService(`/admin/users-count`);
  return http.get();
};
const getFirstNameSuggestions = (q) => {
  let queryString = `q=${q}`
  const http = new HttpService(`/admin/firstName-suggestion?${queryString}`);
  return http.get();
};

// const getAllVehicles = filters => {
//   const {
//     userId,
//     pagination: { current, pageSize },
//     search,
//     garage,
//     sortFilters: { sortField, sortOrder },
//     start,
//     end,
//   } = filters;
//   let queryString = `garage=${garage}&q=${search}&limit=${pageSize}&page=${current}&sortField=${
//     sortField || ''
//   }&sortOrder=${sortOrder || ''}&start=${start}&end=${end}`;
//   const http = new HttpService(`/vehicles/${userId}?${queryString}`);
//   return http.get();
// };

const getAllVehicles = filters => {
  const {
    pagination: { current, pageSize },
    status,
    search,
    customer,
    start,
    end,
    years,
    sortFilters: { sortField, sortOrder },
  } = filters;
  let queryString = `customer=${customer || ''}&q=${search}&limit=${pageSize}&page=${current}&sortField=${
    sortField || ''
  }&sortOrder=${sortOrder || ''}&status=${status}&start=${start}&end=${end}&years=${years}`;
  const http = new HttpService(`/vehicle?${queryString}`);
  return http.get();
};

export default {
  updateGarageName,
  getAllCustomers,
  addAdmin,
  getAdmin,
  addUsers,
  getUser,
  getUsers,
  getAllAdmin,
  updateUser,
  updateAdmin,
  getGarages,
  addOwner,
  getGarageOwners,
  getOwner,
  getAllUsersName,
  getCustomersName,
  getAllCustomer,
  getCustomer,
  addCustomer,
  updateCustomer,
  getAllCustomerExport,
  getAllCustomerExportWithSalesData,
  deleteUser,
  deleteMultipleUsers,
  getBusinessUsers,
  addBusinessUsers,
  getUsersCount,
  getAllVehicles,
  getAllAdminExport,
  addVehicle,
  getAllVehiclesPerCustomer,
  deleteVehicle,
  deleteVehicles,
  getAllUsers,
  updateDashboardGarageName,
  updateGarageLabourRate,
  getFirstNameSuggestions,
};
