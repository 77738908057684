/* eslint-disable prettier/prettier */

import React, { useState, useEffect, useContext } from 'react';
import { Form, Row, AutoComplete, Select } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
// import { Button, Select } from 'antd';
import TextInput from '../../../../components/TextInput';
import RadioInput from '../../../../components/RadioInput';
import SelectInput from '../../../../components/SelectInput';
import CurrencyInput from '../../../../components/CurrencyInput';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable'
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import RequiredAsterisk from '../../../../components/RequiredAsterisk';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import AdminAPIs from '../../../../api/admin';
import { useAlert } from 'react-alert';
import _ from 'lodash';
import ReactAutocomplete from 'react-autocomplete';
import TextArea from 'antd/lib/input/TextArea';
import { ActionMeta, OnChangeValue } from 'react-select';

const CustomerFields = ({
  operation,
  submitHandler,
  data,
  updateState,
  setCustomer,
  customer,
  phone,
  setPhone,
  setAdmin,
  admin,
}) => {updateState
  const customStyles = {
    border: `2px dotted black`,
    valueContainer: (base) => ({
      ...base,
      padding: "8px 10px"
    })
  };
  const history = useHistory()
  const updatedData = data;
  const alert = useAlert();
  const [form] = Form.useForm();
  const [garages, setGarages] = useState([]);
  const [options, setOptions] = useState([]);
  const [lastNameSuggestions, setLastNameSuggestions] = useState([])
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    garage: '',
    discount: '',
    labourRate: '',
  });
  const emailString = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  const discountLevel = [0, 5, 10, 15];
  const [, forceUpdate] = useState({});
  // const { Option, OptGroup } = Select;
  const customerIdFields = [
    'province',
    'postalCode',
    'labourRate',
    'street',
    'city',
    'notes',
    'status',
    'discount',
    'company',
  ];
  const results = localStorage.getItem('usersName');
  var names = [JSON.parse(results) || []];
  // data.mobile = phone;

  const [fname, setFName] = useState([]);
  const [lname, setLName] = useState([]);
  data.mobile = phone;
  // data.email='';
  useEffect(() => {
    const results = localStorage.getItem('usersName');
    var names = [JSON.parse(results) || []];
    for (let i = 0; i < names[0].length; i++) {
      fname.push(names[0][i]?.firstName);
      lname.push(names[0][i]?.lastName);
    }
    setFName(names[0]);
    setLName(names[0]);
  }, []);
  const firstNameOptions = fname.map((items, i) => {
    return { label: [items.firstName], value: items.firstName, data: items };
  });
  const lastNameOptions = lname.map((items, i) => {
    return { label: [items.lastName], value: items.lastName, data: items };
  });
  function handleChange(value) {}

  const [collapsed, setCollapsed] = useState('false');
  /********/

  const handleErrors = e => {
    let err = { ...errors };
    if (data.email == '') err.email = '* Enter email';
    else if (!data.email.match(emailString)) err.email = '* Enter a valid email';
    else if (data.email != '') err.email = '';
    if (data.firstName == '') err.firstName = '* Enter first name';
    if (data?.firstName != '' && !isValidName(data.firstName)) err.firstName = '* Please enter a valid name';
    else if (data.firstName != '') err.firstName = '';
    if (data.lastName == '') err.lastName = '* Enter last name';
    if (data?.lastName != '' && !isValidName(data.lastName)) err.lastName = '* Please enter a valid name';
    else if (data.lastName != '') err.lastName = '';
    if (data.labourRate == '') err.labourRate = '* Enter labour rate';
    if (data.labourRate != '') err.labourRate = '';
    if (data.discount == '') err.discount = '* Select discount';
    if (data.discount != '') err.discount = '';
    if (data.garage == '') err.garage = '* Select garage';
    if (data.garage != '') err.garage = '';
    if (phone == undefined || phone == '' && data.mobile == '') err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber((phone || data.mobile))) err.mobile = '* Enter valid phone number';
    else if (data.mobile != '' || isValidPhoneNumber(phone)) err.mobile = '';
    if (data.discount === 'Please Select') err.discount = '* Select discount';
    setErrors(err);
  };


  const discountError = (event)=>{
    
    
        if (data.discount == '') setErrors((prev) => ({ ...prev, ['discount']: ` * Select discount` }));
       if (data.discount == 'Please Select') setErrors((prev) => ({ ...prev, ['discount']: ` * Select discount` }));
        else if (data.discount != '') setErrors((prev) => ({ ...prev, ['discount']: `` }));
  }

  const garageError = ()=>{
      if (data.garage == '') setErrors((prev) => ({ ...prev, ['garage']: ` * Select garage` }));
      else if (data.garage != '') setErrors((prev) => ({ ...prev, ['garage']: `` }));
  }

  const onChangeError = (event)=>{
   event.persist()
   const decimalRegex = /^\d{1,}(\.\d{0,4})?$/
    if(event.target.name==='labourRate'){
      if (data?.labourRate == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter labour rate` }));
      else if (data?.customerId?.labourRate.toString() != ''&& data?.customerId?.labourRate.toString().match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
      else if(!data?.labourRate?.match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter valid labour rate` }));
      else if (data?.labourRate != ''&& data.labourRate?.match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
      // if (data.labourRate != '' && Math.sign(data?.labourRate) < 1) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter valid labour rate` }));
      // if (data.discount != '' && Math.sign(data?.labourRate) === 1) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
      
    }

    if(event.target.name === 'email'){
      if (data.email == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter email` }));
      else if (!data.email.match(emailString)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter a valid email` }));
      else if (data.email != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
    }

    if (event.target.name==="mobile"){
      if (phone == undefined || phone == '' && data.mobile == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter mobile number` }));
      else if (!isValidPhoneNumber((phone || data.mobile))) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter valid phone number` }));
      else if (data.mobile != '' || isValidPhoneNumber(phone)) setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }));
    }

    if (event.target.name === "firstName"){
      if (event.target.value == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter first name` }));
      else if (data?.firstName != '' && !isValidName(event.target.value)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
      else if (event.target.value != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
    }

    if(event.target.name === "lastName"){
    if (event.target.value == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter last name` }));
    else if (data?.lastName != '' && !isValidName(event.target.value)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
    else if (event.target.value != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
    }
  }

  // const onChangeRemoveError = (event)=>{
  //   setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }));
  // }


  // const handleError = (event) => {
  //   if (event?.target?.value == '')
  //   setErrors((prev) => ({ ...prev, [event?.target?.name]: `${event?.target?.name} required` }));
  // }

  // const removeError = (event) => {
  //   console.log("blur",event.target.name)
  //   if (event?.target?.value !== '')
  //   setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }));
  // }
  
  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getGarages();
        const { garages } = response.data;
        const garageOptions = garages.map((item, i) => {
          return { label: item.name, value: item._id };
        });
        setGarages(garageOptions);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);

  const isValidName = name => {
    return name && /^[a-zA-Z ]{2,30}$/.test(name);
  };

  const handlePhone = (e) => {
    setPhone(e);
    handlePhoneErrors(e)
  }

  const handleSearch = async (value) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if(value){
    setTypingTimeout(() =>
      setTimeout(async () => {
        const response = await AdminAPIs.getFirstNameSuggestions(value);
        const {usersName} = response.data;
        let firstNames = [];
        let lastNames = [];
        firstNames = usersName && usersName?.map((data, i) => {
          lastNames.push({key :i, value:data.lastName,})
          return { key :i, value:data.firstName, obj: data}
          //  return {'key': i.toString(), 'value' : data.firstName}
          })
        setOptions(firstNames);
        setLastNameSuggestions(lastNames);
        // setFirstInputName(value)
      }, 1000)
    )};
  };

  const onChnageLastName =(val, obj)=>{
    setCustomer({...customer, lastName: val})
  }
  
  const handlePhoneErrors = (e) => {
    let err = { ...errors };
    let phone = e;
    if (phone == undefined || phone == '' && data.mobile == '') err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(phone)) err.mobile = '* Enter valid phone number';
    else if ( isValidPhoneNumber(phone)) err.mobile = '';
    setErrors(err)
  }

  const handleSubmit = e => {
    e.preventDefault();
    data.status = data?.customerId?.status;
    data.mobile = phone;
    setIsValidFirstName(isValidName(data?.firstName));
    setIsValidLastName(isValidName(data?.lastName));
    handleErrors();
    if (
      errors.firstName == '' &&
      errors.lastName == '' &&
      errors.email == '' &&
      errors.labourRate == '' &&
      errors.discount == '' &&
      errors.garage == '' &&
      isValidPhoneNumber(data.mobile) &&
      (data.email &&
        data.firstName &&
        data.lastName &&
        data.mobile &&
        data.labourRate &&
        data.discount) != ''
    ) {
    if (isValidName(data?.firstName) && isValidName(data?.lastName)) {
      if (data.mobile && data.mobile.length >= 10 && isValidPhoneNumber(data.mobile)) {
        const updatedUser = { ...data, mobile: phone };
        submitHandler(updatedUser);
      } else {
        handleErrorMessages({ customMessage: `Please enter a valid phone number.` }, alert);
      }
    }}
  };
  const onChange = name => {
    if(operation == 'edit'){
      data.customerId.garage = name;
      setCustomer({ ...customer, customerId: { ...customer.customerId, garage: name } });
    }else {
      data.garage = name;
    }
  };

  const onSearch = value => { };

  const [labelValue] = [...garages];


  const handleOnCancelClick = e =>{
    e.preventDefault();
    history.push("/admin/customer")
  }


  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/admin/customer">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">
                {operation === 'add' ? 'Add Customer' : 'Edit Customer'}
              </p>
            </Link>
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <form className="flex flex-col px-5 py-6 bg-white app_form" onSubmit={e => handleSubmit(e)} noValidate>
          <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
            {/* <SelectInput
              label="User Type"
              name="role"
              onChange={updateState}
              value={data?.role}
              required
              placeholder="select type"
              defaultOption="Select User Type"
            >
              <option value="customer">Customer</option>
              <option value="business_user">Business User</option>
            </SelectInput>
            {data?.role === 'business_user' && (
              <TextInput
                label="Company"
                onChange={updateState}
                name="company"
                value={data?.customerId?.company}
                required={false}
                placeholder="Company Name"
              />
            )} */}
            {/* <div className="flex flex-col input__wrap">
              <label className="inline text-small text-text mb-1">
                First Name
                <RequiredAsterisk />
              </label>
              <ReactAutocomplete
                shouldItemRender={(item, value) =>
                  item?.firstName?.toLowerCase().indexOf(value?.toLowerCase()) > -1
                }
                getItemValue={item => item?.firstName}
                items={names[0]}
                name="firstName"
                renderItem={(item, isHighlighted) => (
                  <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>{item?.firstName}</div>
                )}
                value={data?.firstName}
                onChange={e => setCustomer({ ...customer, firstName: e.target.value })}
                onSelect={val => setCustomer({ ...customer, firstName: val })}
                className="px-4 py-3 bg-white text-body-2 text-text focus:outline-none rounded"
              />
            </div>
            <div className="flex flex-col input__wrap">
              <label className="inline text-small text-text mb-1">
                Last Name
                <RequiredAsterisk />
              </label>
              <ReactAutocomplete
                shouldItemRender={(item, value) =>
                  item?.lastName?.toLowerCase().indexOf(value?.toLowerCase()) > -1
                }
                getItemValue={item => item?.lastName}
                items={names[0]}
                name="lastName"
                autoHighlight={true}
                renderItem={(item, isHighlighted) => (
                  <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>{item?.lastName}</div>
                )}
                value={data?.lastName}
                onChange={e => setCustomer({ ...customer, lastName: e.target.value })}
                onSelect={val => setCustomer({ ...customer, lastName: val })}
              />
            </div> */}
            <div className="flex flex-col input__wrap">
              {/* <label className="inlintypee text-small text-text mb-1">
                First Name
                <RequiredAsterisk />
              </label>
            <AutoComplete
              className=" bg-white text-body-2 text-text focus:outline-none rounded"
            onSearch={handleSearch}
            onChange={(val) => setCustomer({...customer, firstName: val})}
            onSelect={(val, options) => {
              const userData = options?.obj || {};
              const customMobile = options?.obj ? options.obj.mobile : "";
              (setCustomer || setCustomer)({ ...(customer || data), firstName: val, ...userData});
              setPhone(customMobile)
                            // handleAdminValidation(userData)
            }}
            value={data.firstName}
            placeholder="first name"
            options={options}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            />
               {/* <label className="inlintypee text-small text-text mb-1">
                First Name
                <RequiredAsterisk />
              </label> */}
              {/* <CreatableSelect
                 isClearable
                 inputProps={{
                   required: true,
                  pattern: '^[a-zA-Z ]{2,30}$',
                  onInvalid: 'this.setCustomValidity("First name must be alphabetical and non-empty")',
                }}
                options={firstNameOptions}
                name="firstName"
                value={{value: data?.firstName, label: data?.firstName}}
                onChange={val =>{
                  data.firstName = val?.value
                  const userData = val?.data || {};
                  const customMobile = val?.data ? val.data.mobile : "";
                  (setAdmin || setCustomer)({ ...(admin || data), firstName: val?.value, ...userData});
                  setPhone(customMobile)
                }}
                styles={customStyles}
                onBlur={handleErrors}
              /> */}
              
            <TextInput
              label="First Name"
              onChange={updateState}
              name="firstName"
              value={data?.firstName}
              required
              placeholder="FirstName"
              onBlur={onChangeError}
              onkeypress={onChangeError}
              
              // onBlur={handleErrors}
             //  onkeypress={handleErrors}
            />

              {/* {!isValidFirstName ? (
                <p className="text-error font text-sm">* Please enter a valid first name</p>
              ) : null} */}
              {errors && errors.firstName !== '' ? (
                  <p className="text-error font text-sm">{errors.firstName}</p>
                ) : null}
            </div>
            <div className="flex flex-col input__wrap">
              {/* <label className="inline text-small text-text mb-1">
                Last Name
                <RequiredAsterisk />
              </label>
              <CreatableSelect
              isClearable
              inputProps={{
                required: true,
                pattern: '^[a-zA-Z ]{2,30}$',
                oninvalid: 'this.setCustomValidity("Last name must be alphabetical and non-empty")',
              }}
                options={lastNameOptions}
                name="lastName"
                value={{value: data?.lastName, label: data?.lastName}}
                onChange={val => {
                  data.lastName = val?.value;
                  const userData = val?.data || {};
                  const mobile = val?.data ? val.data.mobile : "";
                  (setAdmin || setCustomer)({ ...(admin || data), lastName: val?.value, ...userData });
                  setPhone(mobile)
                }}
                styles={customStyles}
                onBlur={handleErrors}
              /> */}

              <TextInput
              label="Last Name"
              onChange={updateState}
              name="lastName"
              value={data?.lastName}
              required={true}
              placeholder="Lastname"

              onBlur={onChangeError}
              onkeypress={onChangeError}

              // onBlur={handleErrors}
              // onkeypress={handleErrors}
              />

              {/* {!isValidLastName ? (
                <p className="text-error font text-sm">* Please enter a valid last name</p>
              ) : null} */}
              {errors && errors.lastName !== '' ? (
                  <p className="text-error font text-sm">{errors.lastName}</p>
                ) : null}
            </div> 
            {/* <TextInput
              label="First Name"
              onChange={updateState}
              name="firstName"
              value={data?.firstName}
              required={true}
              placeholder="John"
            /> */}
            {/* <TextInput
              label="Last Name"
              onChange={updateState}
              name="lastName"
              value={data?.lastName}
              required={true}
              placeholder="Doe"
            /> */}
            <div>
              <label>
                Mobile Number
                <RequiredAsterisk />
              </label>
              <PhoneInput
                label="Mobile"
                international
                countryCallingCodeEditable={true}
                defaultCountry="CA"
                onChange={ e => handlePhone(e)}
                name="mobile"
                value={phone !== '' ? phone : data?.mobile}
                required={true}
                placeholder="123-456-7890"
                maxLength="16"
                minLength="10"
                // onBlur={handleErrors}
                onBlur={onChangeError}
                onkeypress={onChangeError}
              />
              {errors && errors.mobile !== '' ? (
                  <p className="text-error font text-sm">{errors.mobile}</p>
                ) : null}
            </div>
            <div>
            <TextInput
              label="Email"
              onChange={updateState}
              name="email"
              value={data?.email}
              required={true}
              placeholder="john.doe@mail.com"
              // onBlur={handleErrors}
              // onkeypress={handleErrors}
              onBlur={onChangeError}
              onkeypress={onChangeError}
              />
            {errors && errors.email !== '' ? (
                  <p className="text-error font text-sm">{errors.email}</p>
                ) : null}
            </div>
            {/* {data.role === 'owner' && (
              <TextInput
                label="Garage"
                onChange={updateState}
                name="garage"
                value={data?.garage}
                required={true}
                placeholder="test"
              />
            )} */}
            {/* {['customer', 'business_user'].includes(data.role) && ( */}
            <>
              <div className="flex flex-col">
                <label className="text-small text-text">Status</label>
                <div className="flex flex-row mt-3 mb-10">
                <RadioInput
                    label="Active"
                    defaultChecked={operation === "edit" ? customer?.customerId?.status === true :customer.status === true}
                    onClick={e => updateState(e)}
                    value="true"
                    name="status"
                  />
                  <RadioInput
                    label="Inactive"
                    defaultChecked={operation === "edit" ? customer?.customerId?.status === false : customer.status === false}
                    onClick={e => updateState(e)}
                    value="false"
                    name="status"
                  />
                </div>
              </div>
              {/* <SelectInput
                label="Garage"
                name="garage"
                onChange={updateState}
                value={data?.customerId.garage}
                required
                placeholder="select garage"
              > */}
                <div className="flex flex-col">
                <label className="inline text-small text-text mb-1">
                  Garage
                  <RequiredAsterisk />
                </label>
                <Select
                  showSearch
                  placeholder="Select garage"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={garageError}
                  onBlur={garageError}
                  onClick={garageError}
                  value={data?.customerId?.garage}
                  options={garages}
                  filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  />
                  
                {/* //*<Select 
                // className="basic-single"
                // classNamePrefix="select"
                // placeholder="Select Garage"
                // isClearable
                // isSearchable
                // name='garage'
                // onChange={onChange}
                // options={garages}
                // value={operation !== 'add' && labelValue ? {label: labelValue.label, value: labelValue.value} : data.garage}
                // styles={customStyles}
                // onBlur={handleErrors}
                // onKeyDown={handleErrors}
                // />  */}
                
                {errors && errors.garage !== '' ? (
                  <p className="text-error font text-sm">{errors.garage}</p>
                ) : null}
                {/* <CreatableSelect
                  showSearch
                  placeholder="select garage"
                  // optionFilterProp="children"
                  options={garages}
                  onChange={onChange}
                  onSearch={onSearch}
                  inputValue={operation !== 'add' && labelValue ? labelValue.label : undefined}
                  styles={customStyles}
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().includes(input.toLowerCase())
                  // }
                >
                  {/* //    {garages.map((garage, i) => (
                //     <option key={i.toString()} value={garage._id}>
                //       {_.startCase(garage.name)}
                //     </option>
                // //   ))}
                </CreatableSelect> */}
              </div>
              <TextInput
                label="Street"
                onChange={updateState}
                name="street"
                value={data?.street}
                required={false}
                placeholder="e.g 100 Bayview Ave"
              />
              <TextInput
                label="City"
                onChange={updateState}
                name="city"
                value={data?.city}
                required={false}
                placeholder="Toronto"
              />
              <TextInput
                label="Province"
                onChange={updateState}
                name="province"
                value={data?.province}
                required={false}
                placeholder="Ontario"
              />
              <TextInput
                label="Postal Code"
                onChange={updateState}
                name="postalCode"
                value={data?.postalCode}
                required={false}
                placeholder="e.g M1M 1M1"
                maxLength="11"
              />
              <div>
              <SelectInput
                label="Discount Level"
                name="discount"
                value={data?.discount}
                onChange={updateState}
                required={true}
                defaultOption="Select Discount Level"
                // onBlur={handleErrors}
                // onClick={handleErrors}
                onFocus={discountError}
                onBlur={discountError}
                onClick={discountError}
              >
                {discountLevel.map((level, i) => (
                  <option key={i.toString()} value={level}>
                    {level + '%'}
                  </option>
                ))}
              </SelectInput>
              {errors && errors.discount !== '' ? (
                  <p className="text-error font text-sm">{errors.discount}</p>
                ) : null}
              </div>
              <div>
              {/* <CurrencyInput
                label="Labour Rate / Hour"
                onChange={e => updateState(e, 'labourRate')}
                name="labourRate"
                value={data?.labourRate}
                required={true}
                placeholder="0.00"
                onBlur={handleErrors}
                onkeypress={handleErrors}
              /> */}
              <TextInput
                label="Labour Rate / Hour"
                onChange={e => updateState(e, 'labourRate')}
                value={customer?.labourRate || data?.customerId?.labourRate}
                name="labourRate"
                required={true}
                placeholder="0.00"
                // onBlur={handleErrors}
                // onkeypress={handleErrors}
                onBlur={onChangeError}
                onkeypress={onChangeError}
                // step="0.01"
              />
              {errors && errors.labourRate !== '' ? (
                  <p className="text-error font text-sm">{errors.labourRate}</p>
                ) : null}
              </div>
              {/* <div className="flex flex-col  w-full col-span-full">
                <label className="text-small text-text mb-2">
                  <div>
                    Shop&apos;s Private Notes
                    {<span className="grey_text"> (max 300 characters) </span>}
                  </div>
                </label>
                <TextArea
                  // className="notes-editor-height"
                  // onKeyDown={e => checkCharacterCount(e, shopPrivateNotesRef)}
                  // ref={shopPrivateNotesRef}
                  name="notes"
                  // modules={modules}
                  onChange={value =>
                    setCustomer({
                      ...data,
                      customerId: { ...data.customerId, notes: value },
                    })
                  }
                  // value={data?.notes || ''}
                />
              </div> */}
            </>
            {/* )} */}
            {/* <div className="flex flex-col">
              <label className="text-small text-text">Status</label>
              <div className="flex flex-row mt-3 mb-10">
                <RadioInput
                  label="Active"
                  defaultChecked={data?.status === true}
                  onClick={updateState}
                  value="true"
                  name="status"
                />
                <RadioInput
                  label="Inactive"
                  defaultChecked={data?.status === false}
                  onClick={updateState}
                  value="false"
                  name="status"
                />
              </div>
            </div> */}
          </Row>
          <div className="text-right">
          <button onClick={handleOnCancelClick} className="px-5 py-3 mt-5 mx-2 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80">
                Cancel
            </button>
            <button
              type="submit"
              className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
            >
              {operation === 'add' ? 'Add Customer' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
CustomerFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.string,
  updateState: PropTypes.func,
  setCustomer: PropTypes.func,
  customer: PropTypes.object,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  setAdmin: PropTypes.func,
  admin: PropTypes.object,
};

export default CustomerFields;

// /* eslint-disable prettier/prettier */

// import React, { useState, useEffect, useContext } from 'react';
// import { Layout, Menu, Dropdown, Space, Input, Form, Row, Col } from 'antd';
// import { FiArrowLeft } from 'react-icons/fi';
// import { Link, useHistory } from 'react-router-dom';
// // import { Button, Select } from 'antd';
// import TextInput from '../../../../components/TextInput';
// import RadioInput from '../../../../components/RadioInput';
// import SelectInput from '../../../../components/SelectInput';
// import CurrencyInput from '../../../../components/CurrencyInput';
// import PropTypes from 'prop-types';
// import CreatableSelect from 'react-select/creatable';
// import Select from 'react-select';
// import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
// import flags from 'react-phone-number-input/flags';
// import RequiredAsterisk from '../../../../components/RequiredAsterisk';
// import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
// import AdminAPIs from '../../../../api/admin';
// import { useAlert } from 'react-alert';
// import _ from 'lodash';
// import ReactAutocomplete from 'react-autocomplete';
// import TextArea from 'antd/lib/input/TextArea';
// import { ActionMeta, OnChangeValue } from 'react-select';

// const CustomerFields = ({
//   operation,
//   submitHandler,
//   data,
//   updateState,
//   setCustomer,
//   customer,
//   phone,
//   setPhone,
//   setAdmin,
//   admin,
// }) => {
//   const customStyles = {
//     border: `2px dotted black`,
//     valueContainer: (base) => ({
//       ...base,
//       padding: "8px 10px"
//     })
//   };
//   const history = useHistory()
//   const updatedData = data;
//   const alert = useAlert();
//   const [form] = Form.useForm();
//   const [garages, setGarages] = useState([]);
//   const [isValidFirstName, setIsValidFirstName] = useState(true);
//   const [isValidLastName, setIsValidLastName] = useState(true);
//   // const [phone, setPhone] = useState();
//   // const [user, setUser] = useState({
//   //   firstName: '',
//   //   lastName: '',
//   //   mobile: '',
//   //   email: '',
//   //   role: data?.role ?? '',
//   //   ...data,
//   //   customerId: {
//   //     city: '',
//   //     province: '',
//   //     street: '',
//   //     postalCode: '',
//   //     labourRate: '',
//   //     garage: '',
//   //     status: false,
//   //     discount: '',
//   //     company: '',
//   //     ...data.customerId,
//   //   },
//   // });
//   const discountLevel = [0, 5, 10, 15];
//   const [, forceUpdate] = useState({});
//   // const { Option, OptGroup } = Select;
//   const customerIdFields = [
//     'province',
//     'postalCode',
//     'labourRate',
//     'street',
//     'city',
//     'notes',
//     'status',
//     'discount',
//     'company',
//   ];
//   const results = localStorage.getItem('usersName');
//   var names = [JSON.parse(results) || []];
//   // data.mobile = phone;

//   const [fname, setFName] = useState([]);
//   const [lname, setLName] = useState([]);
//   data.mobile = phone;
//   useEffect(() => {
//     const results = localStorage.getItem('usersName');
//     var names = [JSON.parse(results) || []];
//     for (let i = 0; i < names[0].length; i++) {
//       fname.push(names[0][i]?.firstName);
//       lname.push(names[0][i]?.lastName);
//     }
//     setFName(names[0]);
//     setLName(names[0]);
//   }, []);
//   const firstNameOptions = fname.map((items, i) => {
//     return { label: [items.firstName], value: items.firstName, data: items };
//   });
//   const lastNameOptions = lname.map((items, i) => {
//     return { label: [items.lastName], value: items.lastName, data: items };
//   });
//   function handleChange(value) {}

//   const [collapsed, setCollapsed] = useState('false');
//   /********/

//   useEffect(() => {
//     (async () => {
//       try {
//         const response = await AdminAPIs.getGarages();
//         const { garages } = response.data;
//         const garageOptions = garages.map((item, i) => {
//           return { label: item.name, value: item._id };
//         });
//         setGarages(garageOptions);
//       } catch (e) {
//         // const message = handleErrorMessages(e);
//         // setError(message);
//       }
//     })();
//   }, []);

//   const isValidName = name => {
//     return name && /^[a-zA-Z ]{2,30}$/.test(name);
//   };

//   const handleSubmit = e => {
//     e.preventDefault();
//     data.mobile = phone;
//     setIsValidFirstName(isValidName(data?.firstName));
//     setIsValidLastName(isValidName(data?.lastName));
//     if (isValidName(data?.firstName) && isValidName(data?.lastName)) {
//       if (data.mobile && data.mobile.length >= 10 && isPossiblePhoneNumber(data.mobile)) {
//         const updatedUser = { ...data, mobile: phone };
//         submitHandler(updatedUser);
//       } else {
//         handleErrorMessages({ customMessage: `Please enter a valid phone number.` }, alert);
//       }
//     }
//   };
//   const onChange = name => {
//     data.garage = name;
//   };

//   const onSearch = value => { };



//   const handleOnCancelClick = e =>{
//     e.preventDefault();
//     history.push("/admin/customer")
//   }

//   return (
//     <>
//       <div className="filter--outer mb-4">
//         <div className="d-flex top--heading1">
//           <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
//             <Link to="/admin/customer">
//               <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
//               <p className="inline-block align-middle">
//                 {operation === 'add' ? 'Add Customer' : 'Edit Customer'}
//               </p>
//             </Link>
//           </h2>
//         </div>
//       </div>
//       <div className="FormWrapper">
//         <form className="flex flex-col px-5 py-6 bg-white app_form" onSubmit={e => handleSubmit(e)}>
//           <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
//             {/* <SelectInput
//               label="User Type"
//               name="role"
//               onChange={updateState}
//               value={data?.role}
//               required
//               placeholder="select type"
//               defaultOption="Select User Type"
//             >
//               <option value="customer">Customer</option>
//               <option value="business_user">Business User</option>
//             </SelectInput>
//             {data?.role === 'business_user' && (
//               <TextInput
//                 label="Company"
//                 onChange={updateState}
//                 name="company"
//                 value={data?.customerId?.company}
//                 required={false}
//                 placeholder="Company Name"
//               />
//             )} */}
//             {/* <div className="flex flex-col input__wrap">
//               <label className="inline text-small text-text mb-1">
//                 First Name
//                 <RequiredAsterisk />
//               </label>
//               <ReactAutocomplete
//                 shouldItemRender={(item, value) =>
//                   item?.firstName?.toLowerCase().indexOf(value?.toLowerCase()) > -1
//                 }
//                 getItemValue={item => item?.firstName}
//                 items={names[0]}
//                 name="firstName"
//                 renderItem={(item, isHighlighted) => (
//                   <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>{item?.firstName}</div>
//                 )}
//                 value={data?.firstName}
//                 onChange={e => setCustomer({ ...customer, firstName: e.target.value })}
//                 onSelect={val => setCustomer({ ...customer, firstName: val })}
//                 className="px-4 py-3 bg-white text-body-2 text-text focus:outline-none rounded"
//               />
//             </div>
//             <div className="flex flex-col input__wrap">
//               <label className="inline text-small text-text mb-1">
//                 Last Name
//                 <RequiredAsterisk />
//               </label>
//               <ReactAutocomplete
//                 shouldItemRender={(item, value) =>
//                   item?.lastName?.toLowerCase().indexOf(value?.toLowerCase()) > -1
//                 }
//                 getItemValue={item => item?.lastName}
//                 items={names[0]}
//                 name="lastName"
//                 autoHighlight={true}
//                 renderItem={(item, isHighlighted) => (
//                   <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>{item?.lastName}</div>
//                 )}
//                 value={data?.lastName}
//                 onChange={e => setCustomer({ ...customer, lastName: e.target.value })}
//                 onSelect={val => setCustomer({ ...customer, lastName: val })}
//               />
//             </div> */}
//             <div className="flex flex-col input__wrap">
//               <label className="inline text-small text-text mb-1">
//                 First Name
//                 <RequiredAsterisk />
//               </label>
//               <CreatableSelect
//                 isClearable
//                 inputProps={{
//                   required: true,
//                   pattern: '^[a-zA-Z ]{2,30}$',
//                   onInvalid: 'this.setCustomValidity("First name must be alphabetical and non-empty")',
//                 }}
//                 options={firstNameOptions}
//                 name="firstName"
//                 value={{value: data?.firstName, label: data?.firstName}}
//                 onChange={val =>{
//                   data.firstName = val?.value
//                   const userData = val?.data || {};
//                   const customMobile = val?.data ? val.data.mobile : "";
//                   (setAdmin || setCustomer)({ ...(admin || data), firstName: val?.value, ...userData});
//                   setPhone(customMobile)
//                 }}
//                 styles={customStyles}
//               />
//               {!isValidFirstName ? (
//                 <p className="text-small text-danger">Please enter a valid first name</p>
//               ) : null}
//             </div>
//             <div className="flex flex-col input__wrap">
//               <label className="inline text-small text-text mb-1">
//                 Last Name
//                 <RequiredAsterisk />
//               </label>
//               <CreatableSelect
//               isClearable
//               inputProps={{
//                 required: true,
//                 pattern: '^[a-zA-Z ]{2,30}$',
//                 oninvalid: 'this.setCustomValidity("Last name must be alphabetical and non-empty")',
//               }}
//                 options={lastNameOptions}
//                 name="lastName"
//                 value={{value: data?.lastName, label: data?.lastName}}
//                 onChange={val => {
//                   data.lastName = val?.value;
//                   const userData = val?.data || {};
//                   const mobile = val?.data ? val.data.mobile : "";
//                   (setAdmin || setCustomer)({ ...(admin || data), lastName: val?.value, ...userData });
//                   setPhone(mobile)
//                 }}
//                 styles={customStyles}
//               />
//               {!isValidLastName ? (
//                 <p className="text-small text-danger">Please enter a valid last name</p>
//               ) : null}
//             </div> 
//             {/* <TextInput
//               label="First Name"
//               onChange={updateState}
//               name="firstName"
//               value={data?.firstName}
//               required={true}
//               placeholder="John"
//             /> */}
//             {/* <TextInput
//               label="Last Name"
//               onChange={updateState}
//               name="lastName"
//               value={data?.lastName}
//               required={true}
//               placeholder="Doe"
//             /> */}
//             <div>
//               <label>
//                 Mobile Number
//                 <RequiredAsterisk />
//               </label>
//               <PhoneInput
//                 label="Mobile"
//                 international
//                 countryCallingCodeEditable={true}
//                 defaultCountry="CA"
//                 onChange={setPhone}
//                 name="mobile"
//                 value={phone !== '' ? phone : data?.mobile}
//                 required={true}
//                 placeholder="123-456-7890"
//                 maxLength="16"
//                 minLength="10"
//               />
//             </div>
//             <TextInput
//               label="Email"
//               onChange={updateState}
//               name="email"
//               value={data?.email}
//               required={true}
//               placeholder="john.doe@mail.com"
//             />
//             {/* {data.role === 'owner' && (
//               <TextInput
//                 label="Garage"
//                 onChange={updateState}
//                 name="garage"
//                 value={data?.garage}
//                 required={true}
//                 placeholder="test"
//               />
//             )} */}
//             {/* {['customer', 'business_user'].includes(data.role) && ( */}
//             <>
//               <div className="flex flex-col">
//                 <label className="text-small text-text">Status</label>
//                 <div className="flex flex-row mt-3 mb-10">
//                 <RadioInput
//                     label="Active"
//                     defaultChecked={operation === "edit" ? data?.customerId?.status === true : data.status === true}
//                     onClick={e => updateState(e)}
//                     value="true"
//                     name="status"
//                   />
//                   <RadioInput
//                     label="Inactive"
//                     defaultChecked={operation === "edit" ? data?.customerId?.status === false : data.status === false}
//                     onClick={e => updateState(e)}
//                     value="false"
//                     name="status"
//                   />
//                 </div>
//               </div>
//               {/* <SelectInput
//                 label="Garage"
//                 name="garage"
//                 onChange={updateState}
//                 value={data?.customerId.garage}
//                 required
//                 placeholder="select garage"
//               > */}
//               <div className="flex flex-col">
//                 <label className="inline text-small text-text mb-1">
//                   Garage
//                   <RequiredAsterisk />
//                 </label>
//                 <Select 
//                 className="basic-single"
//                 classNamePrefix="select"
//                 placeholder="Select Garage"
//                 isClearable
//                 isSearchable
//                 name='garage'
//                 onChange={onChange}
//                 options={garages}
//                 value={operation !== 'add' && labelValue ? {label: labelValue.label, value: labelValue.value} : data.garage}
//                 styles={customStyles}
//                 />
//                 {/* <CreatableSelect
//                   showSearch
//                   placeholder="select garage"
//                   // optionFilterProp="children"
//                   options={garages}
//                   onChange={onChange}
//                   onSearch={onSearch}
//                   inputValue={operation !== 'add' && labelValue ? labelValue.label : undefined}
//                   styles={customStyles}
//                   // filterOption={(input, option) =>
//                   //   option.children.toLowerCase().includes(input.toLowerCase())
//                   // }
//                 >
//                   {/* //    {garages.map((garage, i) => (
//                 //     <option key={i.toString()} value={garage._id}>
//                 //       {_.startCase(garage.name)}
//                 //     </option>
//                 // //   ))}
//                 </CreatableSelect> */}
//               </div>
//               {/* </SelectInput> */}
//               <TextInput
//                 label="Street"
//                 onChange={updateState}
//                 name="street"
//                 value={data?.street}
//                 required={false}
//                 placeholder="e.g 100 Bayview Ave"
//               />
//               <TextInput
//                 label="City"
//                 onChange={updateState}
//                 name="city"
//                 value={data?.city}
//                 required={false}
//                 placeholder="Toronto"
//               />
//               <TextInput
//                 label="Province"
//                 onChange={updateState}
//                 name="province"
//                 value={data?.province}
//                 required={false}
//                 placeholder="Ontario"
//               />
//               <TextInput
//                 label="Postal Code"
//                 onChange={updateState}
//                 name="postalCode"
//                 value={data?.postalCode}
//                 required={false}
//                 placeholder="e.g M1M 1M1"
//                 maxLength="11"
//               />
//               <SelectInput
//                 label="Discount Level"
//                 name="discount"
//                 value={data?.discount}
//                 onChange={updateState}
//                 required={true}
//                 defaultOption="Select Discount Level"
//               >
//                 {discountLevel.map((level, i) => (
//                   <option key={i.toString()} value={level}>
//                     {level + '%'}
//                   </option>
//                 ))}
//               </SelectInput>
//               <CurrencyInput
//                 label="Labour Rate / Hour"
//                 onChange={updateState}
//                 name="labourRate"
//                 value={data?.labourRate}
//                 required={true}
//                 placeholder="0.00"
//               />
//               {/* <div className="flex flex-col  w-full col-span-full">
//                 <label className="text-small text-text mb-2">
//                   <div>
//                     Shop&apos;s Private Notes
//                     {<span className="grey_text"> (max 300 characters) </span>}
//                   </div>
//                 </label>
//                 <TextArea
//                   // className="notes-editor-height"
//                   // onKeyDown={e => checkCharacterCount(e, shopPrivateNotesRef)}
//                   // ref={shopPrivateNotesRef}
//                   name="notes"
//                   // modules={modules}
//                   onChange={value =>
//                     setCustomer({
//                       ...data,
//                       customerId: { ...data.customerId, notes: value },
//                     })
//                   }
//                   // value={data?.notes || ''}
//                 />
//               </div> */}
//             </>
//             {/* )} */}
//             {/* <div className="flex flex-col">
//               <label className="text-small text-text">Status</label>
//               <div className="flex flex-row mt-3 mb-10">
//                 <RadioInput
//                   label="Active"
//                   defaultChecked={data?.status === true}
//                   onClick={updateState}
//                   value="true"
//                   name="status"
//                 />
//                 <RadioInput
//                   label="Inactive"
//                   defaultChecked={data?.status === false}
//                   onClick={updateState}
//                   value="false"
//                   name="status"
//                 />
//               </div>
//             </div> */}
//           </Row>
//           <div className="text-right">
//           <button onClick={handleOnCancelClick} className="px-5 py-3 mt-5 mx-2 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80">
//                 Cancel
//             </button>
//             <button
//               type="submit"
//               className="px-5 py-3 mt-5 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80"
//             >
//               {operation === 'add' ? 'Add Customer' : 'Save Changes'}
//             </button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };
// CustomerFields.propTypes = {
//   title: PropTypes.string,
//   operation: PropTypes.string,
//   submitHandler: PropTypes.func,
//   data: PropTypes.object,
//   error: PropTypes.string,
//   updateState: PropTypes.func,
//   setCustomer: PropTypes.func,
//   customer: PropTypes.object,
//   phone: PropTypes.string,
//   setPhone: PropTypes.func,
//   setAdmin: PropTypes.func,
//   admin: PropTypes.object,
// };

// export default CustomerFields;